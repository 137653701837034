<template>
  <!-- <Header></Header> -->

  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" @click="this.$router.push('/')">
        <img class="img-fluid" src="../assets/logo.svg" alt="">
      </a>
    </div>
  </nav>


  <div class="main-height">
    <section class="signin">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12 mt-2">
              <h2 class="fw-bold">Sign In</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <form @submit.prevent="doSignIn">
                <div class="register-form">
                  <div class="input-group">
                    <input required="" v-model="email" type="text" name="email"  class="input">
                    <label class="user-label">Email</label>
                  </div>
                  <div class="input-group mt-2">
                    <input required="" v-model="password" type="password" name="password"  class="input">
                    <label class="user-label">Password</label>
                  </div>
                </div>
                <div v-if="error.length" class="alert-danger mt-3 pe-3 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                  {{ error }}
                </div>
                <div class="mt-2">
                  <a role="button" class="forget" data-toggle="modal" @click="showModal = true">Forgot password?</a>
                </div>
                <div class="col-12 mt-3">
                  <button type="submit" class="btn text-white w-100 rounded-1">
                    <span v-show="!loading">Enter</span>
                    <div v-show="loading" class="button-loading">
                      <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="row">

          </div>
          <div class="row">
            <div class="col-12">
              <div class="login-signup-now d-flex">
                <p>New to ProCricket?</p>
                <a class="ms-1" role="button" @click="this.$router.push('/')">Sign up now.</a>
              </div>
            </div>
            <div class="col-12">
              <div class="recaptcha ">
                This page is protected by Google reCAPTCHA to ensure you're not a bot.
                <a class="readmoretxt" @click="showReadMore = !showReadMore" role="button" >Learn more.</a>
              </div>
              <div class="collapse d-block" id="collapseExample" v-if="showReadMore">
                <div class="recaptcha-expand">
                  The information collected by Google reCAPTCHA is subject to the Google Privacy
                  Policy and Terms of Service, and is used for providing, maintaining, and improving the reCAPTCHA service and for
                  general security purposes (it is not used for personalized advertising by Google).
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- forget password modal  -->
  <div class="modal d-block" v-if="showModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title fw-bold" id="exampleModalLongTitle">Forgot Password</h2>
          <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close"  @click="showModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <section class="forgetpassword-page">
            <div class="row">
              <div class="col-sm-12 col-12">
                <div class="register-form">
                  <div class="input-group mt-1">
                    <input v-model="modalEmail" required="" type="email" name="modalEmail" autocomplete="off" class="input">
                    <label class="user-label" v-if="!modalEmail">Email</label>
                  </div>
                </div>
                <div v-if="modalError.length" class="alert-danger mt-3 pe-3 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                  {{ modalError }}
                </div>
                <div class="row">
                  <div class="col-sm-12 col-12 mt-3">
                    <button role="button" data-toggle="modal" data-dismiss="modal" data-target="#exampleModal"
                      class="btn text-white w-100 rounded-1"  @click="doForgetPassword" >
                      <span v-show="!modalloading">Submit</span>
                      <div v-show="modalloading" class="button-loading">
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <!-- popup modal  -->
  <div class="modal d-block" v-if="forgetPassword" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="card border border-0 w-100">
          <div class="row mx-0">
            <!-- LOGO  -->
            <div class="col-1 bg-success green"></div>
            <div class="col-1 d-flex justify-content-center align-self-center px-1">
              <img class="green-tick-img" src="../assets/green-tick.png" />
            </div>
            <!-- message  -->
            <div class="col-8 ps-2 vl">
              <p class="mb-0 mt-2 fw-bold">Success</p>
              <p class="popup-text mb-2 text-secondary">We’ve sent instructions on how to change your password to the following email address: <b>{{resetEmail}}</b>.
                Please do check your spam folder.</p>
            </div>
            <!-- close button  -->
            <div class="col-2 align-self-center text-center px-0">
              <!-- <a role="button" data-dismiss="modal" class="close">Close</a> -->
              <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" @click="forgetPassword=false">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer></Footer>

</template>

<script>
import {defineComponent} from 'vue'
import AuthService from '@/services/auth-service'
import UsersService from '@/services/users-service'
import Footer from '@/components/Footer.vue'
import LeagueService from '@/services/league-service'
import { event } from 'vue-gtag'
import {PasswordResetRequest} from "@/model/models";

export default defineComponent({
  components: {
    Footer
  },
  setup() {
    const login = () => {
      event('login', { method: 'Google' })
    }
    return {
      login
    }
  },
  data() {
    return {
      email: '',
      modalEmail: '',
      resetEmail: '',
      password: '',
      error: '',
      modalError: '',
      forgetPassword: false,
      showModal: false,
      showReadMore: false,
      loading: false,
      modalloading: false
    }
  },
  methods: {
    onEnter() {
      this.doSignIn();
    },
    doForgetPassword() {
      if (this.modalloading) return;
      this.modalloading = true;

      if (this.modalEmail == null || this.modalEmail === '') {
        this.modalError = "Please enter email address.";
        this.modalloading = false;
      } else {
        AuthService.initPasswordReset(new PasswordResetRequest(this.modalEmail)).then(()=> {
          localStorage.setItem('forgetPassword', "true")
          localStorage.setItem('resetEmail', this.modalEmail);
          window.location.reload();
        }).catch(() => {
          this.modalError = 'Email does not exist. Please try again with a valid email.';
          this.modalloading = false;
        })
      }
    },
    closeForgetPassword() {
      this.forgetPassword = false
    },
    doSignIn() {
      if (this.loading) return;
      this.loading = true;
      AuthService.signIn(this.email, this.password).then(() => {
        this.doLoadPlans()
      }).catch(() => {
       this.error = 'Login failed';
       this.loading = false;
      })
    },
    doLoadPlans() {
      UsersService.getUserStaticData().then(() => {
        UsersService.getUserProfile().then(() => {
          const userProfile = JSON.parse(localStorage.getItem('userProfile'))
          const userPlan = JSON.parse(localStorage.getItem('userPlan'))
          if (userProfile.planId == null || userProfile.planId === '') {
            this.$router.push('/plans')
          } else if (userProfile.planActive && userProfile.planStatus) {
            // there is active, paid plan, check end date
            if (userProfile.planEndDate >= Date.now()) {
              LeagueService.getLeagueList().then(() => {
                //should test if dynamicData loading should be here
                this.$router.push('/events')
              })
            } else {
              this.$router.push('/plans')
            }
          } else if (userProfile.planActive && !userProfile.planStatus) {
            // plan is chosen but not activated
            if (userPlan.trial) {
              // go to trial activation page
              this.$router.push('/trial-activation')
            } else {
              // go to choosing plan from beginning in case they forgot what have chosen
              localStorage.removeItem('userPlan')
              this.$router.push('/plans')
            }
          } else {
            // payment is in process
            this.$router.push('/payment-process')
          }
        }).catch(() => {
          this.error = 'Login failed';
          this.loading = false;
        })
      }).catch(() => {
        this.error = 'Login failed';
        this.loading = false;
      })
    }
  },
  beforeMount() {
    if (localStorage.getItem('access_token')) {
      this.doLoadPlans()
    }
    if (localStorage.getItem('email')) {
      this.email = localStorage.getItem('email');
    }
    if (localStorage.getItem('sessionExpired')) {
      this.error = "Session expired. Log in again."
      localStorage.removeItem("sessionExpired")
    }
    if (localStorage.getItem('forgetPassword')) {
      this.forgetPassword=true
      this.resetEmail = localStorage.getItem('resetEmail')
      localStorage.removeItem("forgetPassword")
      localStorage.removeItem("resetEmail")
    }

    setTimeout(() => {
      this.forgetPassword = false;
    }, 7000);
  },
})
</script>

<style>
@import '../assets/style.css';
</style>
